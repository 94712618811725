export const coordinateArray = [
    [0, 24],
    [0, -24],
    [24, 0],
    [-24, 0],
    [-8, 24],
    [8, 24],
    [-8, -24],
    [8, -24],
    [24, -8],
    [24, 8],
    [-24, -8],
    [-24, 8]
  ];